// Please change the below module name if any module name in route changes
export enum Modules{
    'dashboard'='dashboard',
    'sales'='sales',
    'availability'='availability',
    'scorecard'='scorecard',
    'content-quality'='content-quality',
    'discover'='discover',
    'reporting' = 'reporting',
    'notification' = 'notification',
    'configuration'='configuration',
    'user-management' = 'user-management',
    'pricing' = 'pricing',
    'share-of-search' = 'share-of-search'
}

export enum ModuleName {
    'Dashboard' = 'Dashboard',
    'Sales' = 'Sales',
    'Pricing' = 'Pricing',
    'Availability' = 'Inventory',
    'Content_Quality' = 'Content Quality',
    'Scorecard' = 'Scorecard Tracking',
    'Reporting' = 'Reporting',
    'User_Management' = 'Account Settings',
    'Share_Of_Search' = 'Share Of Search',
    'Notification_Intelligence' = 'Notification Intelligence',
    'Discover' = 'Discover'

}

export enum SubMenuName {
	'BRAND_MARKET_SHARE' = 'Brand Market Share',
	'MARKET_SHARE_ANALYSIS' = 'Market Share Analysis' ,
    'DEALER_ANALYSIS' = 'Dealer Analysis',
    'SCORECARD_PRODUCT' = 'Product Tracking',
    'SCORECARD_BRAND' = 'Brand Tracking',
    'SCORECARD_STORE' = 'Store Tracking',
}