import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  readonly frequencyListStorageKey: string = 'scrappingFrequency';
  readonly currencyListStorageKey: string = 'scrappingCurrency';
  readonly mlServicesStorageKey: string = 'scrappingMLservices';
  readonly globalFilterStorageKey: string = 'globalFilterStorageKeyService';
  readonly calendarTimelineCustomRangeStorageKey: string = 'calendarTimelineCustomRange';
  readonly scorecardPage: string = 'scorecardPageService';
  readonly scorecardPageSize: string = 'scorecardPageSizeService';
  readonly scorecardDefaultListType: string = 'scorecardDefaultListTypeService';
  readonly scorecardAllLocalFilters: string = 'scorecardAllLocalFiltersService';
  readonly scorecardUserOnlyLocalFilters: string = 'scorecardUserOnlyLocalFiltersService';
  readonly scorecardSearched: string = 'scorecardSearchedService';
  readonly scorecardSort: string = 'scorecardSortService';
  readonly scorecardCol: string = 'scorecardColService';
  readonly scorecardInitialCol: string = 'scorecardInitialColService'
  readonly userStorageKey: string = 'user'
  readonly scorecardLocalFilters: string = 'scorecardLocalFiltersService';
  readonly customComponentKey: string = 'customComponent'
  readonly latestDataMessageStorageKey: string = 'latestDataMessage';
  constructor() { }

  // Set local storage
  set(key:string, data:any):void {
    try{
      data = JSON.stringify(data);
      localStorage.setItem(key, data);
    }
    catch(err){
      console.error('Error saving to localStorage', err);
    }
  }

  // Get local storage
  get(key:string){
    try{
      let decodedData:any;
      let user = localStorage.getItem(key);
      if(user){
        decodedData = JSON.parse(user);
      }
      else{
        decodedData = localStorage.getItem(key);
      }
      let decodedDataType = typeof decodedData;
      if(decodedDataType == 'object' || decodedDataType == 'string' || decodedDataType == 'boolean' || decodedDataType == 'number'){
        return decodedData;
      }
    }
    catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }

  // Remove from local storage
  removeItem(key:string){
    localStorage.removeItem(key);
  }

  // Clear local storage
  clear(){
    localStorage.clear();
  } 

  // Clear All Storage
  clearAll(){
    this.clear();
    this.clearSessionStorage();
  }

// SESSION STORAGE FUNCTIONALITY 

    // Set session storage
    sessionStorageSet(key:string, data:any):void {
      try{
        data = JSON.stringify(data);
        sessionStorage.setItem(key, data);
      }
      catch(err){
        console.error('Error saving to Session Storage', err);
      }
    }
  
    // Get session storage
    sessionStorageGet(key:string){
      try{
        let decodedData:any;
        let user = sessionStorage.getItem(key);
        if(user){
          decodedData = JSON.parse(user);
        }
        else{
          decodedData = sessionStorage.getItem(key);
        }
        let decodedDataType = typeof decodedData;
        if(decodedDataType == 'object' || decodedDataType == 'string' || decodedDataType == 'boolean' || decodedDataType == 'number'){
          return decodedData;
        }
      }
      catch (e) {
        console.error('Error getting data from Session Storage', e);
        return null;
      }
    }
  
    // Remove from session storage
    removeSessionItem(key:string){
      sessionStorage.removeItem(key);
    }
  
    // Clear session storage
    clearSessionStorage(){
      sessionStorage.clear();
    } 
}
