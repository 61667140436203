import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, TitleCasePipe } from '@angular/common';
import { MenuComponent } from '@ds-common-components/menu/menu.component';
import { FnPipe } from '@ds-pipes/fn.pipe';
import { MarketplaceStoreLogoComponent } from '@ds-common-components/marketplace-store-logo/marketplace-store-logo.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { RightPanelComponent } from '@ds-common-components/right-panel/right-panel.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DateTimeFormatePipe } from '@ds-pipes/date-time-format.pipe';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { KeywordTrendsComponent } from '@ds-common-components/keyword-trends/keyword-trends.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { TooltipComponent } from '@ds-common-components/tooltip/tooltip.component';
import { InfoProductComponent } from '@ds-common-components/scorecard/info-product/info-product.component';
import { Routes, RouterModule } from '@angular/router';
import { DiscoverComponent } from '@ds-private-layouts/discover/discover.component';
import { QuickCompareComponent } from '@ds-common-components/discover/quick-compare/quick-compare.component';
import { InsightComponent } from '@ds-private-layouts/profile-insight/insight.component';
import { NotificationsComponent } from '@ds-private-layouts/notifications/notifications.component';
import { NotificationHeadersComponent } from '@ds-common-components/right-panel/notification/notification-headers/notification-headers.component';
import { NotificationStoreDetailComponent } from '@ds-common-components/right-panel/notification/notification-details/notification-store-detail/notification-store-detail.component';
import { NotificationProductDetailComponent } from '@ds-common-components/right-panel/notification/notification-details/notification-product-detail/notification-product-detail.component';
import { CatalogHeaderComponent } from '@ds-common-components/catalogue/catalog-header/catalog-header.component';
import { NotificationKeywordDetailComponent } from '@ds-common-components/right-panel/notification/notification-details/notification-keyword-detail/notification-keyword-detail.component';
import { NotificationsActionComponent } from '@ds-common-components/actions/notifications-action/notifications-action.component';
import { FilterTopComponent } from '@ds-common-components/filters/filter-top/filter-top.component';
import { LeftFiltersComponent } from '@ds-common-components/filters/left-filters/left-filters.component';
import { AngularResizedEventModule } from 'angular-resize-event';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { BulkActionsComponent } from '@ds-common-components/bulk-actions/bulk-actions.component';
import { MultilineTooltipComponent } from '@ds-common-components/multiline-tooltip/multiline-tooltip.component';
import { AddEditTeamMemberComponent } from '@ds-common-components/right-panel/add-edit-team-member/add-edit-team-member.component';
import { UserManagementComponent } from '@ds-common-components/actions/user-management/user-management.component';
import { BuildsActionComponent } from '@ds-common-components/actions/builds-action/builds-action.component';
import { SegmentationGridComponent } from '@ds-common-components/segmentation-grid/segmentation-grid.component';
import { DashboardHeadersComponent } from '@ds-common-components/right-panel/insight/dashboard-headers/dashboard-headers.component';
import { ManageProductComponent } from '@ds-common-components/right-panel/insight/dashboard-details/manage-product/manage-product.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ManageMappingComponent } from '@ds-common-components/right-panel/insight/dashboard-details/manage-mapping/manage-mapping.component';
import { ProductInfoComponent } from '@ds-common-components/right-panel/insight/dashboard-details/manage-mapping/product-info/product-info.component';
import { MappedActionComponent } from '@ds-common-components/right-panel/insight/dashboard-details/manage-mapping/mapped-action/mapped-action.component';
import { ProductCatalogueGridComponent } from '@ds-common-components/scorecard/product-catalogue-grid/product-catalogue-grid.component';
import { TagsDropdownComponent } from '@ds-common-components/ngx-grid-common/tags-dropdown/tags-dropdown.component';
import { CompareInfoComponent } from '@ds-common-components/discover/quick-compare/compare-info/compare-info.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AvailableProductListComponent } from '@ds-common-components/discover/available-product-list/available-product-list.component';
import { EditUserDetailsComponent } from '@ds-common-components/right-panel/edit-user-details/edit-user-details.component';
import { ScrapedProductsComponent } from '@ds-private-layouts/scorecard/profile-information/scraped-products/scraped-products.component';
import { RolesActionComponent } from '@ds-common-components/actions/roles-action/roles-action.component';
import { RolesDetailComponent } from '@ds-common-components/right-panel/access-matrix/roles-detail/roles-detail.component';
import { NumberPipe } from '@ds-pipes/number.pipe';
import { CommingSoonComponent } from '@ds-common-components/comming-soon/comming-soon.component';
import { NoValueCheckerPipe } from '@ds-pipes/no-value-checker.pipe';
import { NgxGridDrilldownComponent } from '@ds-common-components/ngx-grid-drilldown/ngx-grid-drilldown.component';
import { HealthDashboardDataComponent } from '@ds-common-components/dashboard/health-dashboard-data/health-dashboard-data.component';
import { ProductDescriptionComponent } from '@ds-common-components/product-description/product-description.component';
import { ChartWalkthroughComponent } from '@ds-common-components/chart-walkthrough/chart-walkthrough.component';
import { WidthChangerDirective } from '@ds-directives/width-changer.directive';
import { AvailableProductGridComponent } from '@ds-common-components/discover/available-product-grid/available-product-grid.component';
import { StepLineChartComponent } from '@ds-common-components/charts/step-line-chart/step-line-chart.component';
import { SplineChartComponent } from '@ds-shared/common-components/charts/spline-chart/spline-chart.component';
import { CommonSelectFilterComponent } from '@ds-common-components/common-select-filter/common-select-filter.component';
import { BarChartComponent } from '@ds-common-components/charts/bar-chart/bar-chart.component';
import { BubbleChartComponent } from '@ds-common-components/charts/bubble-chart/bubble-chart.component';
import { StackedBarChartComponent } from '@ds-common-components/charts/stacked-bar-chart/stacked-bar-chart.component';
import { PieChartComponent } from '@ds-common-components/charts/pie-chart/pie-chart.component';
import { ColoumnChartComponent } from '@ds-common-components/charts/coloumn-chart/coloumn-chart.component';
import { SelectActionHeaderComponent } from '@ds-common-components/ngx-grid-common/select-action-header/select-action-header.component';
import { AllChartsComponent } from '@ds-common-components/all-charts/all-charts.component';
import { PriceRangeFilterComponent } from '@ds-common-components/filters/price-range-filter/price-range-filter.component';
import { UpdateSegmentActionComponent } from '@ds-common-components/actions/update-segment-action/update-segment-action.component';
import { SafeHTMLPipe } from '@ds-pipes/safe-html.pipe';
import { ProductSummaryComponent } from '@ds-common-components/scorecard/summary-overlay/product-summary/product-summary.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ManageColumnsComponent } from '@ds-common-components/manage-columns/manage-columns.component';
import { BuildProfileFormComponent } from './common-components/forms/build-profile-form/build-profile-form.component';
import { SecondaryMenuComponent } from '@ds-common-components/secondary-menu/secondary-menu.component';
import { TimelineFiltersComponent } from './common-components/filters/timeline-filters/timeline-filters.component';
import { BreadcrumbCommonComponent } from './common-components/breadcrumb-common/breadcrumb-common.component';
import { SearchCommonComponent } from './common-components/search-common/search-common.component';
import { NgxGridRevampComponent } from './common-components/ngx-grid-revamp/ngx-grid-revamp.component';
import { CommonMappingComponent } from './common-components/ngx-grid-revamp/common-mapping/common-mapping.component';
import { StackedColumnChartComponent } from './common-components/charts/stacked-column-chart/stacked-column-chart.component';
import { CommonTagsDropdownComponent } from './common-components/common-tags-dropdown/common-tags-dropdown.component';
import { ManageProductsComponent } from './common-components/forms/manage-products/manage-products.component';
import { ManageMappingsComponent } from './common-components/forms/manage-mappings/manage-mappings.component';
import { CommonActionDropdownComponent } from './common-components/common-action-dropdown/common-action-dropdown.component';
import { TreemapComponent } from './common-components/charts/treemap/treemap.component';
import { AreaComponent } from './common-components/charts/area/area.component';
import { TimeRangeComponent } from './common-components/time-range/time-range.component';
import { CommonGridPopUpComponent } from './common-components/common-grid-pop-up/common-grid-pop-up.component';
import { ProductFaceComponent } from './common-components/ngx-grid-revamp/product-face/product-face.component';
import { CommonGridActionsComponent } from './common-components/actions/common-grid-actions/common-grid-actions.component';
import { LocalFiltersComponent } from './common-components/filters/local-filters/local-filters.component';
import { CombinedChartComponent } from './common-components/charts/combined-chart/combined-chart.component';
import { ComingSoonPopUpComponent } from './common-components/coming-soon-pop-up/coming-soon-pop-up.component';
import { RadioKpiFilterComponent } from './common-components/filters/radio-kpi-filter/radio-kpi-filter.component';
import { ResizableComponent } from './common-components/resizable/resizable.component';
import { ColumnRangeChartComponent } from './common-components/charts/column-range-chart/column-range-chart.component';
import { ScorecardListComponent } from './common-components/scorecard/scorecard-list/scorecard-list.component';
import { SecondaryScorecardFilterComponent } from './common-components/scorecard/secondary-scorecard-filter/secondary-scorecard-filter.component';
import { PricingHistoryComponent } from './common-components/scorecard/pricing-history/pricing-history.component';
import { InfoStoreComponent } from './common-components/scorecard/info-store/info-store.component';
import { ScorecardActionsComponent } from './common-components/actions/scorecard-actions/scorecard-actions.component';
import { WatchListComponent } from './common-components/scorecard/watch-list/watch-list.component';
import { ScorecardShareComponent } from './common-components/scorecard/scorecard-share/scorecard-share.component';
import { SegmentationComponent } from './common-components/scorecard/segmentation/segmentation.component';
import { ScorecardHeatmapComponent } from './common-components/scorecard/scorecard-heatmap/scorecard-heatmap.component';
import { CalendarHeatmapComponent } from './common-components/scorecard/scorecard-heatmap/calendar-heatmap/calendar-heatmap.component';
import { ResizableDirective } from './common-components/resizable/resizable.directive';
import { CommonInAppMessagesComponent } from './common-components/common-in-app-messages/common-in-app-messages.component';
import { PluralPipe } from './pipes/plural.pipe';
import { NumberSuffixPipe } from './pipes/number-suffix.pipe';
import { HarmonizationActionsComponent } from './common-components/actions/harmonization-actions/harmonization-actions.component';
import { NotificationCatogoryDetailsComponent } from './common-components/right-panel/notification/notification-details/notification-catogory-details/notification-catogory-details.component';
import { NotificationBrandDetailsComponent } from './common-components/right-panel/notification/notification-details/notification-brand-details/notification-brand-details.component';
import { CommonPreferencesComponent } from './common-components/common-preferences/common-preferences.component';
import { BuildGraphCommonMethodsComponent } from './common-components/build-graph-common-methods/build-graph-common-methods.component';
import { CustomCalendarHeatmapComponent } from './common-components/custom-calendar-heatmap/custom-calendar-heatmap.component';
import { CommonInsightsComponent } from './common-components/common-insights/common-insights.component';
import { InsightsComponent } from './common-components/insights/insights.component';
import { CardsComponent } from './common-components/cards/cards.component';
import { CompareViewPopupComponent } from './common-components/compare-view-popup/compare-view-popup.component';
import { HarmonisationComponent } from './common-components/scorecard/harmonisation/harmonisation.component';
import { CommonPiechartSlicingComponent } from './common-components/common-piechart-slicing/common-piechart-slicing.component';
import { HarmonisationByTypeComponent } from './common-components/scorecard/harmonisation/harmonisation-by-type/harmonisation-by-type.component';
import { HarmonisationMappingComponent } from './common-components/scorecard/harmonisation/harmonisation-mapping/harmonisation-mapping.component';
import { AvailableStoreGridComponent } from './common-components/discover/available-store-grid/available-store-grid.component';
import { CommonFiltersComponent } from './common-components/forms/manage-products/common-filters/common-filters.component';
import { GlobalFiltersComponent } from './common-components/filters/global-filters/global-filters.component';
import { WordCloudComponent } from './common-components/charts/word-cloud/word-cloud.component';
import { CommonCardComponent } from './common-components/common-card/common-card.component';
import { NgOptimizedImage } from '@angular/common';
import { RecursiveInnerGridComponent } from './common-components/ngx-grid-revamp/recursive-inner-grid/recursive-inner-grid.component';
import { AddHarmonisationComponent } from './common-components/actions/add-harmonisation/add-harmonisation.component';
import { CommonCustomDashboardComponent } from './common-components/common-custom-dashboard/common-custom-dashboard.component';
import { HarmonisationUnmapActionComponent } from './common-components/actions/harmonisation-unmap-action/harmonisation-unmap-action.component';
import { CommonSlicedLegendsComponent } from './common-components/common-sliced-legends/common-sliced-legends.component';

const appRoutes: Routes = [
  { path: "dicover", component: DiscoverComponent },
  { path: "dashboard", component: InsightComponent },
  { path: "notification", component: NotificationsComponent }
];
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true
  suppressScrollY: false,
  suppressScrollX: false
};

@NgModule({
  declarations: [
    MenuComponent,
    FnPipe,
    MarketplaceStoreLogoComponent,
    RightPanelComponent,
    DateTimeFormatePipe,
    KeywordTrendsComponent,
    TooltipComponent,
    InfoProductComponent,
    QuickCompareComponent,
    CatalogHeaderComponent,
    NotificationHeadersComponent,
    NotificationStoreDetailComponent,
    NotificationProductDetailComponent,
    NotificationKeywordDetailComponent,
    NotificationsActionComponent,
    FilterTopComponent,
    LeftFiltersComponent,
    BulkActionsComponent,
    MultilineTooltipComponent,
    AddEditTeamMemberComponent,
    UserManagementComponent,
    BuildsActionComponent,
    SegmentationGridComponent,
    DashboardHeadersComponent,
    ManageProductComponent,
    ManageMappingComponent,
    ProductInfoComponent,
    MappedActionComponent,
    ProductCatalogueGridComponent,
    TagsDropdownComponent,
    CompareInfoComponent,
    EditUserDetailsComponent,
    AvailableProductListComponent,
    ScrapedProductsComponent,
    RolesActionComponent,
    RolesDetailComponent,
    NumberPipe,
    CommingSoonComponent,
    NoValueCheckerPipe,
    NgxGridDrilldownComponent,
    HealthDashboardDataComponent,
    ProductDescriptionComponent,
    ChartWalkthroughComponent,
    AvailableProductGridComponent,
    WidthChangerDirective,
    StepLineChartComponent,
    SplineChartComponent,
    CommonSelectFilterComponent,
    BarChartComponent,
    BubbleChartComponent,
    StackedBarChartComponent,
    PieChartComponent,
    ColoumnChartComponent,
    SelectActionHeaderComponent,
    AllChartsComponent,
    PriceRangeFilterComponent,
    UpdateSegmentActionComponent,
    SafeHTMLPipe,
    ProductSummaryComponent,
    ManageColumnsComponent,
    SecondaryMenuComponent,
    TimelineFiltersComponent,
    SelectActionHeaderComponent,
    AllChartsComponent,
    PriceRangeFilterComponent,
    UpdateSegmentActionComponent,
    SafeHTMLPipe,
    ProductSummaryComponent,
    ManageColumnsComponent,
    BuildProfileFormComponent,
    SecondaryMenuComponent,
    BreadcrumbCommonComponent,
    SearchCommonComponent,
    NgxGridRevampComponent,
    CommonMappingComponent,
    StackedColumnChartComponent,
    CommonTagsDropdownComponent,
    ManageProductsComponent,
    ManageMappingsComponent,
    CommonActionDropdownComponent,
    TreemapComponent,
    AreaComponent,
    TimeRangeComponent,
    CommonGridPopUpComponent,
    ProductFaceComponent,
    CommonGridActionsComponent,
    LocalFiltersComponent,
    CombinedChartComponent,
    ComingSoonPopUpComponent,
    RadioKpiFilterComponent,
    ScorecardListComponent,
    SecondaryScorecardFilterComponent,
    PricingHistoryComponent,
    InfoStoreComponent,
    ScorecardActionsComponent,
    WatchListComponent,
    ScorecardShareComponent,
    SegmentationComponent,
    ScorecardHeatmapComponent,
    CalendarHeatmapComponent,
    ResizableComponent,
    ColumnRangeChartComponent,
    ResizableDirective,
    CommonInAppMessagesComponent,
    PluralPipe,
    NumberSuffixPipe,
    HarmonizationActionsComponent,
    NotificationCatogoryDetailsComponent,
    NotificationBrandDetailsComponent,
    CommonPreferencesComponent,
    BuildGraphCommonMethodsComponent,
    CustomCalendarHeatmapComponent,
    CommonInsightsComponent,
    CardsComponent,
    CompareViewPopupComponent,
    HarmonisationComponent,
    CommonPiechartSlicingComponent,
    HarmonisationByTypeComponent,
    HarmonisationMappingComponent,
    InsightsComponent,
    AvailableStoreGridComponent,
    GlobalFiltersComponent,
    CommonFiltersComponent,
    WordCloudComponent,
    CommonCardComponent,
    RecursiveInnerGridComponent,
    CommonCustomDashboardComponent,
    AddHarmonisationComponent,
    HarmonisationUnmapActionComponent,
    CommonSlicedLegendsComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    AngularResizedEventModule,
    HighchartsChartModule,
    NgxSliderModule,
    InfiniteScrollModule,
    NgOptimizedImage,
    RouterModule.forChild(appRoutes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      },
      isolate: false
    }),
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [
    MenuComponent,
    MarketplaceStoreLogoComponent,
    RightPanelComponent,
    FilterTopComponent,
    LeftFiltersComponent,
    KeywordTrendsComponent,
    TooltipComponent,
    InfoProductComponent,
    CatalogHeaderComponent,
    BulkActionsComponent,
    MultilineTooltipComponent,
    ProductCatalogueGridComponent,
    TranslateModule,
    ScrapedProductsComponent,
    NumberPipe,
    CommingSoonComponent,
    NgxGridDrilldownComponent,
    ProductDescriptionComponent,
    ChartWalkthroughComponent,
    WidthChangerDirective,
    StepLineChartComponent,
    SplineChartComponent,
    CommonSelectFilterComponent,
    BarChartComponent,
    BubbleChartComponent,
    StackedBarChartComponent,
    PieChartComponent,
    ColoumnChartComponent,
    PerfectScrollbarModule,
    PriceRangeFilterComponent,
    SafeHTMLPipe,
    ProductSummaryComponent,
    ManageColumnsComponent,
    TimelineFiltersComponent,
    BreadcrumbCommonComponent,
    SearchCommonComponent,
    NgxGridRevampComponent,
    StackedColumnChartComponent,
    CommonMappingComponent,
    CommonActionDropdownComponent,
    TreemapComponent,
    NoValueCheckerPipe,
    AreaComponent,
    TimeRangeComponent,
    CommonGridPopUpComponent,
    LocalFiltersComponent,
    CombinedChartComponent,
    ComingSoonPopUpComponent,
    RadioKpiFilterComponent,
    ScorecardListComponent,
    SecondaryScorecardFilterComponent,
    PricingHistoryComponent,
    InfoStoreComponent,
    WatchListComponent,
    ScorecardShareComponent,
    SegmentationComponent,
    ScorecardHeatmapComponent,
    ResizableComponent,
    ColumnRangeChartComponent,
    ResizableDirective,
    CommonInAppMessagesComponent,
    NumberSuffixPipe,
    PluralPipe,
    InfiniteScrollModule,
    CommonPreferencesComponent,
    BuildGraphCommonMethodsComponent,
    CustomCalendarHeatmapComponent,
    CommonInsightsComponent,
    CustomCalendarHeatmapComponent,
    InsightsComponent,
    GlobalFiltersComponent,
    WordCloudComponent,
    CommonCardComponent,
    AvailableStoreGridComponent,
    CardsComponent,
    CompareViewPopupComponent,
    CardsComponent,
    CompareViewPopupComponent,
    CommonPiechartSlicingComponent,
    CommonCustomDashboardComponent,
    AddHarmonisationComponent,
    CommonSlicedLegendsComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    //{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    DecimalPipe,
    TitleCasePipe
  ],
})
export class SharedModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/locale/", ".json");
}