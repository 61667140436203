import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonFunctionsService } from '@ds-common-services/utility-services/common-functions.service';
import { NotificationService } from '@ds-common-services/utility-services/notification.service';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataTransferService } from '@ds-common-services/utility-services/data-transfer.service';
import { StoreScorecardService } from '@ds-private-layouts/scorecard/store-scorecard/store-scorecard.service';

@Component({
  selector: 'app-update-segment-action',
  templateUrl: './update-segment-action.component.html',
  styleUrls: ['./update-segment-action.component.scss']
})
export class UpdateSegmentActionComponent implements OnInit {

  @ViewChild('content') content: ElementRef;
  public segmentMap: any = { "OS": "Official", "RS": "OARP (Reseller)", "GS": "Greyseller", "CP": "Competitor", "OT": "Other" };
  public segments: any[] = Object.keys(this.segmentMap);
  @Input() public data: any; //storeId
  @Input() public profileId: any;
  @Input() public activeSegment: any;
  private destroy$: Subject<boolean> = new Subject();
  constructor(public modalService: NgbModal, public activeModal:NgbActiveModal, public commonFunctions: CommonFunctionsService, private notificationService: NotificationService, private dateTransferService: DataTransferService, private storeScorecardService: StoreScorecardService) { }

  ngOnInit(): void {}

  openModal(data) {}

  updateSegment() {
    const payload = {
      action_on_all: false,
      segment: this.activeSegment,
    }
    if(this.data.size !== 0 ) payload['store_ids'] = [this.data]
    if(this.data.size) payload['store_ids'] = Array.from(this.data); //Bulk segment update
    this.storeScorecardService.updateSegment(payload).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.notificationService.setMessage(
        res["context_code"],
        res["summary"]
      );
      this.activeModal.close(true);
    }, (err: any) => {
      this.notificationService.setMessage(
        err?.error?.context_code,
        err?.error?.summary
      );
    })
  }

}
