import { Injectable } from '@angular/core';
import { HttpRequestService } from '@ds-common-services/http-services/http-request.service';
import { PAYLOAD } from '@ds-shared/enums/common.enum';
import { Service, Resource } from 'ngx-jsonapi';
import { forkJoin, Observable } from 'rxjs';

export class Products extends Resource {
  public attributes = {
    type: '',
    id: '',
  };
}
@Injectable({
  providedIn: 'root'
})
export class ProductListService extends Service<Products>{
  public resource = Products;
  public type = 'products-list';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class ProductListExtraService extends Service<Products>{
  public resource = Products;
  public type = 'scorecard';
  public ttl = 1;
}
@Injectable({
  providedIn: 'root'
})
export class ProductScorecardService extends Service<Products> {

  constructor(public http: HttpRequestService) {
    super();
  }
  getProductDetails(productId: number) {
    return this.http.callGetApi(`scorecard/banner?marketplace_product_id=${productId}`);
  }

  getProductLikesData(product_id: string | number, startDate: any, endDate: any, view: string): Observable<any[]> {
    let filter = `filter[${view}_id]=${product_id}&filter[type]=product${startDate ? `&filter[start_date]=${startDate}` : ''}${endDate ? `&filter[end_date]=${endDate}` : ''}`;
    const headerDetails = this.http.callGetApi(`scorecard/likes?${filter}`);
    const graphDetails = this.http.callGetApi(`scorecard/likes/graph?${filter}`);
    return forkJoin([headerDetails, graphDetails]);
  }

  getProductRankingTrendsData(productId: number, startDate: any, endDate: any) {
    const pathForProductRankingBannerDetails = `scorecard/ranking-trends?marketplace_product_id=${productId}${startDate ? `&filter[start_date]=${startDate}` : ''}${endDate ? `&filter[end_date]=${endDate}`:''}`;
    return this.http.callGetApi(pathForProductRankingBannerDetails);
  }

  getMarketplace(storeId, pageNumber: number) {
    if (storeId) return this.http.callGetApi('marketplaces?status[active]=true&filter[store]=' + storeId + "&page[size]=10&page[number]=" + pageNumber);
    else return this.http.callGetApi('marketplaces?status[active]=true&page[size]=10&page[number]=' + pageNumber);
  }

  getMarketplaceProduct(storeId, pageNumber: number, term) {
    if (!storeId) {
      let path = 'customermarketplaceproducts?page[size]=10&page[number]=' + pageNumber + '&filter[search_pattern]=' + term;
      return this.http.callGetApi(path);
    }
    else {
      let path = 'customermarketplaceproducts?filter[store]=' + storeId + '&page[size]=10&page[number]=' + pageNumber + '&filter[search_pattern]=' + term;
      return this.http.callGetApi(path);
    }
  }
  getBrandMarketplace(brandId, countryId, pageNumber: number) {
    return this.http.callGetApi('brand-marketplaces?status[active]=true&filter[brand]=' + brandId + '&filter[country]=' + countryId + "&page[size]=10&page[number]=" + pageNumber);
  }

  getBrandBenchmarkingData(brandId: number, countryId: number, startDate: any, endDate: any) {
    return this.http.callGetApi(`store-scorecard/brand-benchmarking?customer_brand_id=${brandId}${startDate ? `&start_date=${startDate}` : ''}${endDate ? `&end_date=${endDate}` : ''}&country_id=${countryId}`);
  }

  getProductRating(productId: number, view: string) {
    let filter = `filter[${view}_id]=${productId}&filter[type]=product`;
    return this.http.callGetApi(`scorecard/rating?${filter}`);
  }

  getProductSentimentAnalysisWithDetails(productId: number) {
    const pathForSentimentReview = `scorecard/review?marketplace_product_id=${productId}`;
    const pathForSentimentDetails = `scorecard/sentiment-analysis?marketplace_product_id=${productId}`;
    const reviewDetails = this.http.callGetApi(pathForSentimentReview);
    const sentimentDetails = this.http.callGetApi(pathForSentimentDetails);
    return forkJoin([reviewDetails, sentimentDetails]);
  }

  getPromotionAvailability(productId: number,startDate: any, endDate: any) {
    return this.http.callGetApi(`scorecard/promotions?marketplace_product_id=${productId}${startDate ? `&filter[start_date]=${startDate}` : ''}${endDate ? `&filter[end_date]=${endDate}` : ''}`);
  }
  getCountry(pageNumber: number) {
    return this.http.callGetApi('countries?page[size]=10&page[number]=' + pageNumber);
  }
  getCustomerBrands() {
    return this.http.callGetApi('customerbrands');
  }
  getShareOfSearchBrandData(brandId, countryId, startDate, endDate) {
    return this.http.callGetApi(`store-scorecard/search-share-brand?customer_brand_id=${brandId}&country_id=${countryId}${startDate ? `&start_date=${startDate}` : ''}${endDate ? `&end_date=${endDate}` : ''}`);
  }
  getMarketplaceList(pageNumber: any, search: any, currency_id: number) {
    return this.http.callGetApi(`scorecard/marketplaces?page[size]=10&page[number]=${pageNumber}${search && `&filter[search]=${search}`}&filter[${PAYLOAD.CURRENCY_ID}]=${currency_id}`);
  }
  getStoreList(marketplaceId: any, pageNumber: any, search: any, currency_id: number) {
    return this.http.callGetApi(`scorecard/stores?page[size]=10&page[number]=${pageNumber}&marketplace_id=${marketplaceId}${search && `&filter[search]=${search}`}&filter[${PAYLOAD.CURRENCY_ID}]=${currency_id}`);
  }
  getProductList(marketplaceId: any, storeId: any, pageNumber: any, search: any, currency_id: number) {
    return this.http.callGetApi(`scorecard/products?page[size]=10&page[number]=${pageNumber}&store_id=${storeId}${search && `&filter[search]=${search}`}&filter[${PAYLOAD.CURRENCY_ID}]=${currency_id}`);
  }

  getContentQuality(productId: number,) {
    const path = `scorecard/content-quality?marketplace_product_id=${productId}`
    return this.http.callGetApi(path);
  }
  getScorecardProductMatchingsCount(marketplace_product_id: any) {
    let path = `scorecard/product-matchings/count?marketplace_product_id=${marketplace_product_id}`;
    return this.http.callGetApi(path);
  }
  getReviewsList(marketplace_product_id: number, sentiment_type: string, sentiment_phrase: string) {
    return this.http.callGetApi(`scorecard/product-reviews-list?marketplace_product_id=${marketplace_product_id}${sentiment_type ? `&sentiment_type=${sentiment_type}` : ''}${sentiment_phrase ? `&sentiment_phrase=${sentiment_phrase}` : ''}`);
  }

}
