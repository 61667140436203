import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AccessMatrix } from "@ds-enums/access-matrix.enum";
import {
	AccessMatrixModel,
	RolePermissions
} from "@ds-models/access-matrix.model";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { ModuleName } from "@ds-shared/enums/modules.enum";

@Injectable({
	providedIn: "root"
})
export class AccessMatrixService {
	private roles: AccessMatrixModel[] = [];
	public readonly rolesStorageKey = "capabilities";
	private rolesSpreadKeys = "roles";
	public readonly moduleNameMapping = { 'di': ModuleName.Discover, 'da': ModuleName.Dashboard, 's': ModuleName.Scorecard, 'um': ModuleName.User_Management, 'r': ModuleName.Reporting, 'se': ModuleName.Sales, 'a': ModuleName.Availability, 'pr': ModuleName.Pricing, 'cq': ModuleName.Content_Quality, 'n':ModuleName.Notification_Intelligence, 'sos': ModuleName.Share_Of_Search };
	public readonly predefinedRoles = [
		{ module_code: 'da', read_access: false, write_access: false, export_access: false, full_access: false, sortOrder: 1 },
		{ module_code: 'pr', read_access: false, write_access: false, export_access: false, full_access: false, sortOrder: 2  },
		{ module_code: 'a', read_access: false, write_access: false, export_access: false, full_access: false, sortOrder: 4 },
		{ module_code: 'se', read_access: false, write_access: false, export_access: false, full_access: false, sortOrder: 3  },
		{ module_code: 'cq', read_access: false, write_access: false, export_access: false, full_access: false, sortOrder: 5  },
		{ module_code: 's', read_access: false, write_access: false, export_access: false, full_access: false, sortOrder: 8  },
		{ module_code: 'di', read_access: false, write_access: false, export_access: false, full_access: false, sortOrder: 6  },
		{ module_code: 'r', read_access: false, write_access: false, export_access: false, full_access: false, sortOrder: 9 },
		{ module_code: 'n', read_access: false, write_access: false, export_access: false, full_access: false, sortOrder: 10  },
		{ module_code: 'um', read_access: false, write_access: false, export_access: false, full_access: false,sortOrder: 11  },
		{ module_code: 'sos', read_access: false, write_access: false, export_access: false, full_access: false,sortOrder: 7  },
	  ]
	constructor(
		private localStorageService: LocalStorageService,
		private router: Router,
		private commonFunctions: CommonFunctionsService
	) {}

	getPermissionByRoute(): RolePermissions {
		if (this.userRoles?.length > 0) {
			const isPermissionAvailable = this.findByModule();
			if (isPermissionAvailable)
				return new RolePermissions(isPermissionAvailable);
		}
		this.router.navigate([""]); 
		return new RolePermissions();
	}

	set userRoles(roles: AccessMatrixModel[]) {
		this.roles = roles;
		this.localStorageService.set(
			this.rolesStorageKey,
			globalThis.btoa(JSON.stringify(roles))
		);
		// this.setSpreadRoles();
	}

	get userRoles() {
		if (this.roles && this.roles.length > 0) {
			return this.roles;
		} else {
			try {
				this.roles = JSON.parse(
					globalThis.atob(this.localStorageService.get(this.rolesStorageKey))
				);
				return this.roles;
			} catch {
				this.router.navigate([""]);
				return [];
			}
		}
	}

	private setSpreadRoles() {
		const roleKeys: string[] = [];
		if (this.roles?.length) {
			this.roles.forEach((item: AccessMatrixModel) => {
				const moduleName =
					item.module_name?.toLowerCase().split(" ").join("-") || "";
				const itm = { ...item };
				if (itm.full_access) {
					itm.read_access = true;
					itm.write_access = true;
					itm.export_access = true;
				}
				for (let obj in itm) {
					if (itm[obj] && obj !== "module_id" && obj !== "module_name") {
						roleKeys.push(`${moduleName}-${obj}`);
					}
				}
			});
		}
		this.localStorageService.set(
			this.rolesSpreadKeys,
			globalThis.btoa(JSON.stringify(roleKeys))
		);
	}

	public getSpreadRoles() {
		let spreadRoles = [];
		try {
			spreadRoles = JSON.parse(
				globalThis.atob(this.localStorageService.get(this.rolesSpreadKeys))
			);
			return spreadRoles;
		} catch {
			return [];
		}
	}

	private findByModule() {
		const path = this.router.url.split("/")[1];
		let permission = this.userRoles.find(
			(item) => AccessMatrix[item.module_name] === path
		);
		if (permission?.full_access) {
			permission.export_access = true;
			permission.read_access = true;
			permission.write_access = true;
		}
		if (!permission && path === AccessMatrix["User Management"]) {
			permission = {
				full_access: false,
				export_access: false,
				read_access: false,
				write_access: false
			};
		}
		return permission;
	}

	public getPermissionByModule(moduleName: string): RolePermissions {
		let pickedModule: RolePermissions = new RolePermissions();
		for (let module of this.userRoles) {
			if (AccessMatrix[module.module_name] === moduleName) {
				pickedModule = { ...module };
			}
		}
		if (pickedModule.full_access) {
			pickedModule.read_access = true;
			pickedModule.write_access = true;
			pickedModule.export_access = true;
		}
		return pickedModule;
	}

	navigateAfterLogin() {
		let isDashboardAvailable = false;
		for (let item of this.userRoles) {
			if (item.module_name?.toLowerCase() === AccessMatrix.Dashboard) {
				isDashboardAvailable = true;
				break;
			}
		}
		if (isDashboardAvailable) {
			this.router.navigate(["dashboard"]);
		} else {
			this.router.navigate(["user-management"]);
		}
        this.commonFunctions.watchSecondaryMenu();
	}

	isAuthenticated(): boolean {
		try {
			const token = this.localStorageService.get("jwt");
			const userDetails = JSON.parse(globalThis.atob(token.split(".")[1]));
			if (userDetails.user_id) {
				return true;
			}
			return false;
		} catch {
			return false;
		}
	}

	public getModulesList() {
		const predefinedRolesMap = new Map(this.predefinedRoles.map(role => [role.module_code, role]));
		const MODULES = this.commonFunctions.getSavedUserData()["role"]["modules"];
		return MODULES.map(module => predefinedRolesMap.get(module.module_code)).filter(Boolean).sort((a,b)=>a.sortOrder - b.sortOrder);
	}
}
