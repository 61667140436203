<div class="d-flex flex-wrap gap-16">
    <div  *ngFor="let data of ngxItems.data" class="w-30">
        <div class="box-container box-view p-3">
            <div class="{{commonFunctions.differentBadges[data?.attributes.segment].class}} badgeView mb-3">
                {{commonFunctions.differentBadges[data?.attributes.segment].name | translate}}
            </div>
            <div class="box-container-inner">
                <div class="store-name d-flex align-items-center mb-3">
                        <span 
                            class="verifiedName d-block ellipsis common-text" 
                            *ngIf="data?.attributes.store_name"
                            showMultiTooltipOnTextOverflow 
                            [data]="data?.attributes.store_name">
                        </span>
                        <a 
                            *ngIf="data?.attributes.store_url" 
                            [href]="data?.attributes.store_url" 
                            target="_blank" 
                            class='navigate-link d-flex align-items-center'
                            placement="{{commonFunctions.rtlStatus? 'left' : 'right'}}" 
                            ngbTooltip="{{'Go to Marketplace Store' | translate}}"
                            tooltipClass="info-custom-tooltip">
                            <i class="icon icon-Arrow-up-2 d-inline-block"></i>
                        </a>
                </div>
                <div class="d-flex align-items-start">
                    <p translate>Average Price</p>
                    <div class="gap-8 d-inline-flex align-items-center">
                        <span class="price-value mb-0"
                            [ngClass]="{'empty-price': !data?.attributes?.price}">{{data?.attributes?.avg_price
                            ? (data?.attributes?.currency?.symbol || commonFunctions.currencySelected?.attributes?.symbol) : null}}{{data?.attributes?.avg_price ?
                            (data?.attributes?.avg_price | numberSuffix: 2) : '-'}} </span>
                    </div>
                </div>
                <div class="box-item-col mb-3">
                    <span  class="info-text d-inline-block" translate>Average Ratings</span>
                    <ng-template #starLabel>
                        <span class="star">&#x02605;</span>
                    </ng-template>
                    <div class="ratingLabel" 
                        [ngClass]="commonFunctions.getStarStatus(data?.attributes?.avg_rating)" *ngIf="data?.attributes?.avg_rating !==null">
                        {{data?.attributes?.avg_rating}}
                        <ngb-rating 
                            [max]="1" 
                            [starTemplate]="starLabel" 
                            [rate]="1">
                        </ngb-rating>
                    </div>
                </div>
                <div class="box-item-col mb-3">
                    <span class="info-text d-inline-block" translate>Channel</span>
                    <div class="d-flex align-items-center gap-6">
                        <app-marketplace-store-logo 
                            [storeName]="data?.attributes?.marketplace_name">
                        </app-marketplace-store-logo>
                        <span 
                            class="marketplace-name cursor-pointer" 
                            showTooltipOnTextOverflow
                            [data]="data?.attributes?.marketplace_name">
                        </span>
                    </div>
                </div>
                <div class="box-item-col mb-3">
                    <span class="info-text header" translate>Tracked By</span>
                    <ng-container *ngIf="data.attributes?.tracked_by?.length">
                        <div class="tracked-by-parent d-flex align-items-center main-content" ngbDropdown display="dynamic">
                            <div>{{(data.attributes.tracked_by[0].profile_type.split('')[0]|uppercase)}}</div>
                            <div class="separator">:</div>
                            <div class="profile-name text-truncate"
                                [ngClass]="{'single-profile-name-width':data.attributes.tracked_by.length===1}"
                                showTooltipOnTextOverflow [data]="data.attributes?.tracked_by[0].profile_name"
                                id="scorecardTrackedBy"></div>
                            <span class="link cursor-pointer" id="dropdownBasic1" ngbDropdownToggle
                                *ngIf="data.attributes?.tracked_by.length>1">+{{data?.attributes.tracked_by.length - 1}}</span>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="commonDropdown">
                                <ng-container *ngFor="let tag of data.attributes.tracked_by.slice(1);let last = last">
                                    <div class="tracked-by-parent d-flex align-items-center child-content" [ngClass]="{'mb-1':!last}">
                                        <div>{{tag.profile_type.split('')[0]|uppercase}}</div>
                                        <div class="separator">:</div>
                                        <div class="profile-name text-truncate dropdown-profile-name-width"
                                            showTooltipOnTextOverflow [data]="tag.profile_name"></div>
                                    </div>
                                </ng-container>
            
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="box-item-col">
                    <span class="info-text d-inline-block" translate>Last Updated</span>
                    <span class="box-value-text">
                        {{commonFunctions.formatSimpleDate(data?.attributes?.last_updated)}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
