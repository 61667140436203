import { Component, OnInit, HostListener, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'showTooltipOnTextOverflow,[showTooltipOnTextOverflow]',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input() data: any;
  @Input() ttPosition: any;
	public isVisible: any = false;
	public cele: any = null;
	public pele: any = null;

  constructor(public _el: ElementRef) {
  }

  ngOnInit() { 
    this.detectLastRow();
  }

  @HostListener('mouseenter') onEnter() {
	  this.isVisible = (this._el.nativeElement.offsetWidth < this._el.nativeElement.scrollWidth) ? true : false;
	  this.cele = this._el.nativeElement;
	  this.pele = this._el.nativeElement;
	  this.setCustomTooltipPosition();

  }
  @HostListener('mouseleave') onLeave() {
	  this.isVisible = false;
	  this.removeCustomTooltipPosition();
  }

  detectLastRow() {
		setTimeout(() => {
			const wrappers = document.querySelectorAll(".table-wrapper");
			wrappers.forEach((wrapper) => {
				const table = wrapper.querySelector("table");
				if(table) {
				Array.from(table.rows).forEach((row) => {
					if (!row.hasAttribute("data-mouseover-attached")) {
						row.addEventListener("mouseover", () => {
							const tableRect = wrapper
								.querySelector(".ps")
								.getBoundingClientRect();
							const rowRect = row.getBoundingClientRect();
							const isNearBottom = rowRect.bottom >= tableRect.bottom - 100;
							const tooltipPositionForLastRow = row.querySelectorAll(
								".custom-js-indicator-position"
							);
							tooltipPositionForLastRow.forEach((item) => {
								if (!item.parentElement.parentElement.classList.contains("customTooltipPosition")) {
									item.classList.toggle("top", isNearBottom);
									const triangle = item.querySelector(".dummy-triangle");
									if (triangle) {
										triangle.classList.toggle("arrowTop", isNearBottom);
									}
									const tooltipText =
										item.querySelector<HTMLElement>(".ss-tooltip-text");
									if (tooltipText && isNearBottom) {
										let gapBetweenArrowAndTextWhenBottom = 75;
										let parentElement = tooltipText.parentElement;
										while (parentElement) {
											if (parentElement.matches('#scorecardTrackedBy')) {
												gapBetweenArrowAndTextWhenBottom = 120;
												break;
											}
											parentElement = parentElement.parentElement;
										}
										tooltipText.style.bottom = gapBetweenArrowAndTextWhenBottom + "%"
									}	
								}
							});
							row.setAttribute("data-mouseover-attached", "true");
						});
					}
				});
			}
			});
		}, 50);
  }
	
	setCustomTooltipPosition() {
		if (this.cele.classList.contains('customTooltipPosition')) {
			this.cele.classList.add('customTooltip');
			const toolele = document.querySelector<HTMLElement>(".customTooltip .ss-tooltip-text");
			const triangle = document.querySelector<HTMLElement>(".customTooltip .dummy-triangle");
			while (!this.pele.classList.contains('customTooltipPositionContainer')) {
				this.pele = this.pele.parentElement;
			}
			setTimeout(() => {
				const cele_bounding = this.cele.getBoundingClientRect();
				const pele_bounding = this.pele.getBoundingClientRect();
				const toolele_bounding = toolele.getBoundingClientRect();
				const triangle_bounding = triangle.getBoundingClientRect();
				if (toolele_bounding.bottom > pele_bounding.bottom) {
					const tool_box_position = Math.abs(pele_bounding.top - cele_bounding.top) - toolele_bounding.height + this.pele.scrollTop - (triangle_bounding.height * 4.9);
					const tool_arrow_position = Math.abs(pele_bounding.top - cele_bounding.top) + this.pele.scrollTop;
					if (tool_box_position < 0) {
						this.pele.style.overflow = 'visible';
					}
					else {
						toolele.style.top = `${tool_box_position}px`;
						triangle.style.transform = `rotate(180deg)`;
						triangle.style.top = `${tool_arrow_position}px`;
					}
				}
			}, 100)
		}
	}
	removeCustomTooltipPosition() {
		if (this.cele.classList.contains('customTooltip')) {
			document.querySelector<HTMLElement>(".customTooltip .dummy-triangle")?.style.removeProperty("transform");
			document.querySelector<HTMLElement>(".customTooltip .dummy-triangle")?.style.removeProperty("top");
			document.querySelector<HTMLElement>(".customTooltip .ss-tooltip-text")?.style.removeProperty("top");
			this.pele.style.removeProperty("overflow");
			this.cele.classList.remove('customTooltip');
		}
	}

}

