<ng-template #content let-modal>
    <div class="modal-header">
        <div class="modal-name">
            <h4 class="modal-title" translate>Quick Compare</h4>
            <!-- <i class="icon icon-Info-circle" placement="{{commonFunctions.rtlStatus ? 'right' : 'right'}}" ngbTooltip="{{quickInfo}}" tooltipClass="info-custom-tooltip"></i> -->
        </div>
        <button type="button" class="close" placement="{{commonFunctions.rtlStatus ? 'right' : 'left'}}" ngbTooltip="{{'Close' | translate}}"
        tooltipClass="info-custom-tooltip"  aria-label="Close" (click)="modal.dismiss('Cross click')">
            <i class="icon icon-Close2"></i>
        </button>
    </div>
    <div class="container">
        <div class="data-container compare-carousel compare-tooltip text-align-right-rtl">

            <div class="cont1">
                <div class="text-info-1 product" translate>Products</div>
                <div class="text-info-1 price" translate>Price</div>
                <div class="text-info-1 brand" translate>Brand</div>
                <div class="text-info-1 promotion" translate>Category</div>
                <div class="text-info-1 marketplace" translate>Channel</div>
                <div class="text-info-1 discount" translate>Discount</div>
                <div class="text-info-1 rating" translate>Vouchers</div>
                <div class="text-info-1 category" translate>Rating</div>
                <div class="text-info-1 date" translate>Last Updated</div>
            </div>
            <app-compare-info [productDetail]="customerProductResponse" [type]="'customer'" [productId]="customerProductResponse?.product_id" *ngIf="customerProductResponse" (onCloseAction)="closeModal()"></app-compare-info>
            <div *ngIf='!customerProductResponse' class="no-data-common" ngbAutofocus>
                <div *ngIf="customerDataPresent" class="no-data-found" translate>
                    <div class="img"><i class="icon icon-Search"></i></div>
                    <div class="no-data-text" translate>No data present</div>
                </div>
                <div *ngIf="!customerDataPresent" class="loading-panel">
                    <img src="../../../../../assets/images/spinner.svg" alt="">
                </div>
            </div>
            <ngb-carousel *ngIf='competitorProductResponse' [interval]="0">
                <ng-template (slid)="activeIndex = i" ngbSlide
                    *ngFor="let product of competitorProductResponse; index as i">
                    <app-compare-info [productDetail]="product?.attributes" [type]="'competitor'" [productId]="product?.id" (onCloseAction)="closeModal()"></app-compare-info>
                </ng-template>
            </ngb-carousel>
            <div *ngIf='!competitorProductResponse' class="no-data-common competitor" ngbAutofocus>
                <div *ngIf="competitorDataPresent" class="no-data-found" translate>
                    <div class="img"><i class="icon icon-Search"></i></div>
                    <div class="no-data-text" translate>No data present</div>
                </div>
                <div *ngIf="!competitorDataPresent" class="loading-panel">
                    <img src="../../../../../assets/images/spinner.svg" alt="">
                </div>
            </div>
        </div>
        <div class="comparison">
            <button class="btn btn-primary btn-lg" [disabled]="!customerProductResponse || (!competitorProductResponse || competitorProductResponse?.length==0)" (click)="[deepComparison(),modal.dismiss('Cross click')]" translate>View Deep
                Comparison</button>
        </div>
    </div>
</ng-template>

<ng-template #longContent let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <i class="icon icon-Close2"></i>
        </button>
    </div>
</ng-template>