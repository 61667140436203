export enum MATCHTYPE {
	"category" = "Category Matches",
	"brand" = "Brand Matches",
    "keyword" = "Keyword Matches",
	"rs" = "Reseller Matches",
	"cp" = "Competitor Matches",
	"gs" = "Greyseller Matches",
	"direct" = "Direct Matches"
}

export enum MATCHTYPEBADGECLASS {
    "category" = "badge-category",
	"brand" = "badge-brand",
    "keyword" = "badge-keyword",
	"rs" = "badge-purple",
	"cp" = "badge-blue",
	"gs" = "badge-grey",
	"direct" = "badge-sunshine"
}