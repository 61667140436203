<div class="app-structure">
	<app-menu *ngIf="status"></app-menu>
	<div class="app-content" [ngClass]="{'border-l':router.url!=='/'}">
		<ngx-loading-bar [includeSpinner]="false" color="#f90e54"></ngx-loading-bar>
		<router-outlet></router-outlet>
		<button
			class="float"
			(click)="openModal()"
			*ngIf="accessMatrixService.isAuthenticated() && status"
		>
			<i class="icon icon-Search"></i>
		</button>
	</div>
</div>
<ng-template #globalSearch let-modal>
	<div class="modal-header mb-0 px-0">
		<div class="heading" translate>
			Search for any Product, Store, Brand, Keyword or Category.
		</div>
		<i
			class="icon icon-Close2 cursor-pointer"
			(click)="modal.dismiss('Cross click')"
		>
		</i>
	</div>
	<div class="modal-body d-flex">
		<ng-select
			class="searchTypeDrowdown"
			[notFoundText]="'No Data Found' | translate"
			[loadingText]="'Loading...' | translate"
			[items]="searchType.list"
			(change)="onSearchTypeChange($event)"
			[(ngModel)]="searchType.selected"
			[clearable]="false"
			bindLabel="attributes.type"
		>
			<ng-template ng-option-tmp let-item="item" let-index="index">
				<div>
					<span class="item-name" translate
						>{{item.attributes.type | translate}}</span
					>
				</div>
			</ng-template>
			<ng-template ng-option-tmp let-item="item">
				<span class="float-start" translate
					>{{item.attributes.type | translate}}</span
				>
			</ng-template>
		</ng-select>
		<div class="search-container d-inline-block heading1-alter">
			<ng-select
				[items]="searchedText.length > 0 ? searchedResult.data : recentSearchList.data"
				aria-placeholder="Search"
				[placeholder]="'Search' | translate"
				bindLabel="attributes.name"
				(search)="onSearch($event)"
				(change)="onSearchValueSelection($event)"
				[loadingText]="'Loading...' | translate"
				[notFoundText]="notFoundText"
				[loading]="searchedText.length > 0 ? searchedResult.loading :recentSearchList.loading"
				[clearable]="false"
				[searchFn]="customSearchFn"
			>
				<ng-template ng-option-tmp let-item="item" let-index="index">
					<div>
						<div
							class="scraped-product-start-container justify-content-between"
						>
							<div class="d-flex align-items-center gap-16">
								<div
									class="image-container"
									placement="top-end"
									*ngIf="searchType.selected.attributes.type === 'Product' && (searchedText.length > 0  || index!==0 ) && index !== firstIndex"
								>
									<img
										*ngIf="item?.attributes?.images?.length"
										[src]="item?.attributes?.images[0]"
										alt=""
									/>
									<img
										*ngIf="!item?.attributes?.images?.length"
										src="assets/images/no-image.svg"
										alt=""
									/>
								</div>
								<app-marketplace-store-logo
									[storeName]="item.attributes.marketplace_name"
								></app-marketplace-store-logo>
								<span class="item-name" translate
									>{{item.attributes.name}}
								</span>
							</div>
						</div>
					</div>
				</ng-template>
			</ng-select>
		</div>
	</div>
</ng-template>
